"use client";
import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { captureEvent } from "ui/lib/captureEvent";
import { updateUTMParams } from "ui/lib/misc";
import { cn } from "ui/lib/utils";
import { accessVariant } from "web/components/text-node";
import { useExperiment } from "web/state-containers/experiment";

const MotionLink = motion.create(Link);

interface LinkActionProps extends React.ComponentPropsWithoutRef<typeof Link> {
  event: {
    category?: string;
    action: string;
    [key: string]: any;
  };
  href: string;
  children: React.ReactNode;
}

export const LinkAction: React.FC<LinkActionProps> = ({
  children,
  event,
  href,
  ...props
}) => {
  const handleClick = () => {
    captureEvent({ ...event, label: children });
  };

  return (
    <Link href={href} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

interface ActionProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MotionLink>, "href"> {
  at: string;
  defaultClasses?: string;
  className?: string;
  event?: {
    category?: string;
    action?: string;
    label?: string;
    [key: string]: any;
  };
}

export const Action = React.memo<ActionProps>(
  ({
    at,
    defaultClasses = "text-lg rounded-full cta",
    className,
    event,
    ...props
  }) => {
    const { variant } = useExperiment();
    const { isReady } = useRouter();

    const accessedVariant = useMemo(
      () => accessVariant(at, variant as string),
      [at, variant]
    );

    const [href, setHref] = useState(() => {
      const initial = accessedVariant.href || accessedVariant.link || "";
      return accessedVariant.href ? updateUTMParams(initial).href : initial;
    });

    const sendEvent = useCallback(() => {
      captureEvent({
        category: at,
        action: "find_my_plan_cta_clicked",
        label: accessedVariant.label,
        ...event,
      });
    }, [at, accessedVariant.label, event]);

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        console.log({ href, currentTargetHref: e.currentTarget.href });
        sendEvent();
        props.onClick?.(e);
      },
      [sendEvent, href, props.onClick]
    );

    // Update href when accessedVariant changes
    useEffect(() => {
      const newHref =
        accessedVariant.link ||
        (accessedVariant.href
          ? updateUTMParams(accessedVariant.href).href
          : "");
      setHref(newHref);
    }, [accessedVariant]);

    if (!isReady) return null;

    if (!href) {
      console.log({ at, variant, accessedVariant });
      return null;
    }

    // Use key prop to force re-mount when href changes
    return (
      <MotionLink
        {...props}
        key={href}
        href={href}
        target={
          accessedVariant.target
            ? accessedVariant.target
            : accessedVariant.href
              ? "_self"
              : undefined
        }
        rel="noreferrer"
        onClick={handleClick}
        aria-label={accessedVariant.label}
        className={cn(
          "relative inline-block cursor-pointer",
          defaultClasses,
          className
        )}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.975 }}
      >
        {accessedVariant.label}
      </MotionLink>
    );
  }
);

Action.displayName = "Action";
